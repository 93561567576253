<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>项目审批</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
          <span>{{
              detail.true_name | sub_name
              }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}</div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--<div class="approval-detail-title">-->
                  <!--<span class="approval-detail-tip">审批编号：</span-->
                  <!--&gt;<span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--</div>-->
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{$getTitleByType('会议名称',detail.cid)}}：</span
          ><span class="approval-detail-content">{{detail.name}}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">审批内容：</span
          ><span class="approval-detail-content">
                    <p v-if="detail.shenpi.name"><span>{{$getTitleByType('会议名称',detail.cid)}}由</span>{{detail.shenpi.last_name}}<span>变更为</span>{{detail.shenpi.name}}</p>
                    <p v-if="detail.shenpi.sid"><span>所属学会由</span>{{detail.shenpi.last_sid.join('、')}}<span>变更为</span>{{detail.shenpi.sid.join('、')}}</p>
                    <p v-if="detail.shenpi.meet_form"><span>{{ $getTitleByType('会议', pid.cid) }}形式由</span>{{detail.shenpi.last_meet_form | meet_form(detail.shenpi.last_meet_info)}}<span>变更为</span>{{detail.shenpi.meet_form | meet_form(detail.shenpi.meet_info)}}</p>
                    <p v-if="detail.shenpi.start_time || detail.shenpi.end_time"> <span>{{$getTitleByType('会议日期',detail.cid)}}由</span>{{detail.shenpi.last_start_time + '至' + detail.shenpi.last_end_time}}<span>变更为</span>{{(detail.shenpi.start_time ? detail.shenpi.start_time :  detail.shenpi.last_start_time) + '至' + (detail.shenpi.end_time ? detail.shenpi.end_time : detail.shenpi.last_end_time)}}</p>
                    <p v-if="detail.last_other_info" v-html="detail.last_other_info"></p>

                </span>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
            <span>
              {{
                detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time}}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <div class="name-container">
            <span
            >{{
                detail.shenpi.true_name | sub_name
                }}
              <i
                      :class="
                  detail.shenpi.state === 0 ||  detail.shenpi.state === 3
                    ? 'el-icon-remove'
                    : detail.shenpi.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.shenpi.true_name
                                    }}<i v-if="detail.shenpi.state != 0">({{ detail.shenpi.state | state }})</i>
                                </div>
                                <div class="info-time" v-if="detail.end_time">{{detail.end_time | formatTime}}</div>
                            </div>
                            <div class="info-desc" v-if="detail.shenpi.state === 0">
                                {{ iself(detail.shenpi) ? "我（" : "" }}{{ detail.shenpi.state | state
                                }}{{ iself(detail.shenpi) ? "）" : "" }}
                            </div>
                            <div class="info-desc" v-if="detail.shenpi.state !== 0">
                                {{ detail.shenpi.sp_remark }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                        class="approval-btn-left"
                        v-if="isApprovaler"
                        @click="showDialog(2)"
                >拒绝
                </el-button
                >
                <el-button
                        class="approval-btn-right"
                        v-if="isApprovaler"
                        @click="showDialog(1)"
                >通过
                </el-button
                >
            </div>
        </div>
        <el-dialog
                :visible.sync="editVisible"
                width="30%"
                center
                :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title">{{
          state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                        type="textarea"
                        resize="none"
                        :rows="5"
                        v-model="remark"
                        :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toApproval()" :loading="requestState" :disabled="requestState" >确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import {projectFroms, sub_name, ZHDate} from "../../tools";

    export default {
        components: {ElButton},
        name: "approvalDetail",
        data() {
            return {
                pid: "",
                isApprovaler: false,
                editVisible: false,
                requestState: false,
                state: -1,
                remark: "",
                detail: {},
            };
        },
        created() {
            this.$emit("setIndex", [0, 5]);
            this.init();
        },
        methods: {
            showDialog(type) {
                this.editVisible = true;
                this.state = type;
                this.remark = this.state === 1 ? "同意" : "";
            },
            closeDialog() {
                this.editVisible = false;
                this.state = -1;
            },
            toApproval() {
                if (!this.remark) {
                    this.$message.error(
                        this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                    );
                    return;
                }
                this.requestState = true
                this.$api.apiContent
                    .confirmSetMation({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.detail.shenpi.id,
                        state: this.state,
                        sp_remark: this.remark,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.detail.shenpi.id,
                                state: this.state,
                                sp_remark: this.remark,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.$message.success(res.msg);
                        this.init();
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            init() {
                this.pid = this.$route.query.id;
                this.$api.apiContent
                    .informationConform({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.pid,
                        type: 1,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.pid,
                                type: 1,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.add_time = res.data.add_time
                            ? this.$tools.ZHDate(new Date(res.data.add_time * 1000))
                            : "";
                        res.data.shenpi.start_time = res.data.shenpi.start_time
                            ? this.$tools.ZHDate(new Date(res.data.shenpi.start_time * 1000))
                            : "";
                        res.data.shenpi.end_time = res.data.shenpi.end_time
                            ? this.$tools.ZHDate(new Date(res.data.shenpi.end_time * 1000))
                            : "";
                        res.data.shenpi.last_start_time = res.data.shenpi.last_start_time
                            ? this.$tools.ZHDate(new Date(res.data.shenpi.last_start_time * 1000))
                            : "";
                        res.data.shenpi.last_end_time = res.data.shenpi.last_end_time
                            ? this.$tools.ZHDate(new Date(res.data.shenpi.last_end_time * 1000))
                            : "";
                        this.detail = res.data;
                        this.isApproval();
                    })
                    .catch(() => {
                        this.detail = {};
                    });
            },
            iself: function (item) {
                return item.to === this.$store.state.user.mid;
            },
            isApproval: function () {
                this.isApprovaler = this.detail.shenpi.to === this.$store.state.user.mid && this.detail.shenpi.state === 0;
                this.editVisible = false;
                this.requestState = false
            },
            backPage() {
                this.$tools.goBack();
            },
        },
        computed: {
            isSend: function () {
                return this.detail.user_id === this.$store.state.user.mid;
            },
        },
        watch: {
            $route: "init",
        },
        filters: {
            state(type) {
                // 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                switch (Number(type)) {
                    case 1:
                        return "已通过";
                    case 2:
                        return "已拒绝";
                    default:
                        return "审批中";
                }
            },
            meet_form(type,other){
                let find = projectFroms.find(f=>{  return f.key === Number(type)})
                return find ? find.value + other : other
            },
            formatTime(time) {
                return ZHDate(new Date(time * 1000),6)
            },
            sub_name(str) {
                return sub_name(str)
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .dialog-title {
            color: #000;
            font-size: 24px;
        }

        .dialog-footer {
            .el-button {
                width: 235px;
            }
        }
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .approval-detail-info {
            position: relative;
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            min-height: 600px;
            padding-bottom: 100px;
            .approval-btn {
                display: flex;
                justify-content: center;
                flex-direction: row;
                width: 50%;
                position: absolute;
                bottom: 60px;
                padding-left: 120px;
                .approval-btn-left {
                    width: 180px;
                    background: transparent;
                    border: 1px solid #3b77e7;
                    color: #3b77e7;
                }
                .approval-btn-right {
                    width: 180px;
                    margin-left: 20px;
                    background: #3b77e7;
                    color: #ffffff;
                }
            }
            .approval-list-container {
                display: flex;
                flex-direction: row;
                padding: 40px 80px;
                .approval-list {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    margin-left: 12px;

                    .name-container {
                        position: relative;
                        flex-grow: 1;
                        display: flex;
                        padding-bottom: 50px;
                        flex-direction: row;

                        &.active {
                            &:before {
                                position: absolute;
                                background: #d3d4d6;
                                width: 1px;
                                top: 0px;
                                bottom: 0px;
                                left: 28px;
                                content: "";
                            }
                        }
                        .info-container {
                            flex-grow: 1;
                            margin-left: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .info-top {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                .info-content {
                                    font-size: 20px;
                                    color: #333333;
                                    i {
                                        font-size: 14px;
                                    }
                                }
                                .info-time {
                                    font-size: 16px;
                                    color: #999999;
                                }
                            }
                            .info-desc {
                                margin-top: 2px;
                                font-size: 16px;
                                color: #999999;
                                .info-file {
                                    font-size: 16px;
                                    color: #3b77e7;
                                    margin-top: 12px;
                                }
                                .info-file-up {
                                    position: relative;
                                    width: 250px;
                                    background: transparent;
                                    border: 1px solid #3b77e7;
                                    font-size: 12px;
                                    margin-top: 12px;
                                    padding-left: 50px;
                                    color: #3b77e7;
                                    .info-file-up-logo {
                                        position: absolute;
                                        top: 25%;
                                        left: 20px;
                                    }
                                }
                            }
                            .copy-members {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                margin-top: 20px;
                                .copy-members-name {
                                    margin-right: 10px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                            }
                        }
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            width: 56px;
                            height: 56px;
                            border-radius: 56px;
                            border: 1px solid #3b77e7;
                            text-align: center;
                            /*line-height: 24px;*/
                            font-size: 16px;
                            color: #ffffff;
                            background-color: #3b77e7;
                            position: relative;
                            i {
                                position: absolute;
                                bottom: -5px;
                                right: -5px;
                                font-size: 18px;
                                width: 18px;
                                height: 18px;
                                overflow: hidden;
                                &.el-icon-remove {
                                    color: #ee9900;
                                }
                                &.el-icon-success {
                                    color: #13ce66;
                                }
                                &.el-icon-error {
                                    color: #ff0000;
                                }
                            }
                        }
                    }
                }
                .approval-list-tip {
                    font-size: 18px;
                    color: #333333;
                    flex-shrink: 1;
                    margin-top: 12px;
                }
            }
            .approval-detail-container {
                padding: 40px;
                border-bottom: 1px solid #f0f2f5;
                .approval-detail-title {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: row;
                    font-size: 18px;
                    color: #999999;
                    width: 100%;
                    .approval-detail-file {
                        flex: 7;
                        flex-shrink: 1;
                        display: flex;
                        flex-direction: column;
                        a {
                            font-size: 18px;
                            cursor: pointer;
                            margin-bottom: 8px;
                            display: block;
                            color: #3b77e7;
                            &:hover {
                                font-size: 18px;
                            }
                        }
                    }
                    .approval-detail-tip {
                        flex: 1;
                        flex-shrink: 0;
                        text-align: right;
                    }
                    .approval-detail-content {
                        flex: 7;
                        flex-shrink: 1;
                        color: #333333;
                        span{
                            color: #3b77e7;
                        }
                    }
                }
                .name-container {
                    display: flex;
                    flex-direction: row;
                    padding-left: 40px;
                    .state-container {
                        margin-left: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .content {
                            font-size: 22px;
                            color: #333333;
                            .red {
                                color: red;
                            }
                        }
                        .state {
                            font-size: 16px;
                            margin-top: 6px;
                            color: #13ce66;
                        }
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 65px;
                        height: 65px;
                        border-radius: 65px;
                        text-align: center;
                        cursor: pointer;
                        /*line-height: 24px;*/
                        font-size: 20px;
                        color: #fff;
                        background-color: #3b77e7;
                    }
                }
            }
        }
    }
</style>
